import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import Keycloak from 'keycloak-js';
import { environment } from '../environments/environment';
const keycloak = new Keycloak({
  url: environment.AUTH_URL,
  realm: environment.REALMS,
  clientId: environment.CLIENT,
});
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent implements OnInit {
  token: any;
  constructor(public route:Router){}
  ngOnInit(): void {
    keycloak.init({
      onLoad: 'login-required',
    }).then((authenticated:any) => {
      if (authenticated) {
        this.token = keycloak.token;
        window.location.href=`${environment.URL}/#/login/${this.token}`
      }
    })
  }
  title = 'demo';
}
